<template>
  <div>
    
    <SeoKit />
    
    <TheTopNav class="mx-auto" />
    
    <main class="w-full min-h-screen primary-content flex flex-col relative overflow-hidden">
      <div class="w-full m-auto mt-5" style="min-height:70vh;z-index: 1;">
        <NuxtPage :transition="false"/>
      </div>
    </main>

    <TheFooter class="mt-auto mx-auto hidden md:grid"/>

  </div>
</template>

<script setup>

const subDomain = useSubdomain()

</script>